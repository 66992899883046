"use client";

import { VariantProps, cva } from "class-variance-authority";
import { forwardRef, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { Icons, Path, paths } from "./Icons";

const iconVariants = cva("fill-current", {
  variants: {
    mode: {
      inherit: "",
      primary: "text-blue-500",
      secondary: "text-gray-700",
      warning: "text-yellow-500",
      danger: "text-red-500",
      success: "text-green-500",
      tag: "text-green-600",
      company: "text-indigo-400",
      classification: "text-pink-500",
    },
    size: {
      xs: "h-3 w-3",
      msm: "h-3.5 w-3.5",
      sm: "h-4 w-4",
      md: "h-5 w-5",
      lg: "h-6 w-6",
      xl: "h-8 w-8",
      "2xl": "h-10 w-10",
      "3xl": "h-20 w-20",
    },
  },
});

export type IconVariantProps = VariantProps<typeof iconVariants>;

export type IconProps = IconVariantProps &
  Omit<React.SVGProps<SVGSVGElement>, "ref" | "mode"> & {
    icon: Icons;
    className?: string;
    ref?: React.Ref<SVGSVGElement>;
    title?: string;
  };

export const Icon = forwardRef((props: IconProps, ref: IconProps["ref"]) => {
  const {
    icon,
    className,
    size = "lg",
    mode = "inherit",
    ...otherProps
  } = props;
  const svgRef = useRef<SVGSVGElement | null>();

  const p = paths[icon] || null;
  const path = (p as Path)?.path || p;
  const viewBox = (p as Path)?.viewBox || "0 0 30 30";

  useEffect(() => {
    if (svgRef.current) {
      // fix for vitest
      svgRef.current.focus = () => null;
    }
  }, []);

  return (
    <svg
      ref={(r) => {
        svgRef.current = r;
        if (typeof ref === "function") {
          ref(r);
        }
      }}
      className={twMerge(
        iconVariants({
          mode,
          size,
        }),
        className,
      )}
      viewBox={viewBox}
      aria-hidden="true"
      {...otherProps}
    >
      {path as React.ReactNode}
    </svg>
  );
});

Icon.displayName = "Icon";
